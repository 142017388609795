import { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [showCopied, setShowCopied] = useState(false);

  const handleCopyDiscord = async () => {
    try {
      await navigator.clipboard.writeText('3t2.');
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      setMousePosition({ x: clientX, y: clientY });

      if (isHovered) {
        const messageElement = document.querySelector('.gaming-message');
        if (!messageElement) return;
        
        const rect = messageElement.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;
        
        const angleX = (clientY - centerY) / 20;
        const angleY = -(clientX - centerX) / 20;
        
        messageElement.style.transform = 
          `perspective(1000px) rotateX(${angleX}deg) rotateY(${angleY}deg) scale(1.05)`;
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [isHovered]);

  return (
    <div className="App">
      <div className="cyber-grid" />
      
      <div className="content-container">
        <div className="glitch-container">
          <h1 className="name-title">Daddy Satamqx</h1>
        </div>

        <div 
          className="gaming-message"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => {
            setIsHovered(false);
            document.querySelector('.gaming-message').style.transform = 
              'perspective(1000px) rotateX(0deg) rotateY(0deg) scale(1)';
          }}
        >
          Want to get carried in games? Add Daddy Satamqx!
        </div>

        <div className="discord-card" onClick={handleCopyDiscord}>
          <i className="fab fa-discord discord-icon"></i>
          <div className="discord-tag">
            3t2.
            <div className="copy-tooltip">Click to copy</div>
          </div>
          <div className={`copy-notification ${showCopied ? 'show' : ''}`}>
            <i className="fas fa-check"></i> Copied!
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
